import { useState } from 'react'
import Box from '@mui/material/Box'
import { Chip, Popover, Stack, Typography, List, ListItem, ListItemText } from '@mui/material'

const ReportMultipleMatchesDisplay = ({ multipleMatches }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedMatch, setSelectedMatch] = useState(null)

  const handleClick = (e, match) => {
    setAnchorEl(e.currentTarget)
    setSelectedMatch(match)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setSelectedMatch(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Box>
      <Typography>Multiple marches Found:</Typography>
      <Stack
        direction="row"
        spacing={1}>
        {multipleMatches.map((match, index) => (
          <Chip
            key={index}
            label={match.searchTerm}
            onClick={(e) => handleClick(e, match)}
            className="cursor-pointer bg-blue-100 hover:bg-blue-200"
          />
        ))}
      </Stack>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <Box className="p-4 max-w-sm">
          <Typography className="font-medium mb-2">Possible matches for "{selectedMatch?.searchTerm}":</Typography>
          <List className="max-h-60 overflow-y-auto">
            {selectedMatch?.matches.map((match, index) => (
              <ListItem
                key={index}
                className="py-1">
                <ListItemText primary={match} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </Box>
  )
}

export default ReportMultipleMatchesDisplay
